export const APP_VERSION = '1.0';

export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const API_URL = process.env.REACT_APP_API_URL;
export const WEBSITE_URL = "https://dev.der-beste-coach.de";

// export const API_ROOT = "http://localhost:3001";
// export const API_URL = "http://localhost:3001";

export const MEDIA_BASE = process.env.REACT_APP_MEDIA_BASE;

export const TEMP_API_ROOT = 'http://192.168.1.20:3002';

export const USERROLES = [
	{ value: 'user', label: 'User' },
  	{ value: 'expert_user', label: 'Expert User' },
  	{ value: 'admin', label: 'Admin' }
];

export const ACCESS_STATUS = [
	{ value: 1, label: 'Test User' },
  	{ value: 2, label: 'Full Access' }
];

export const SESSION_RATING = {
	1: 'ratesmile',
	2: 'norate',
	3: 'ratesad'
};

export const CURRENCY_SIGN = '€';
export const CURRENCY_CODE = 'EURO';

export const EXPERT_REQUEST_ACCEPTANCE = {
	0: 'Pending',
	1: 'Accepted',
	2: 'Rejected'
}