import { load } from './utilities';
import React from 'react';
import { Redirect } from 'react-router-dom';

export const ROUTES = {
  ROOT: {
    path: '/',
    exact: true,
    render: () => <Redirect to="/login" />
  },
  ADMIN: {
    path: '/admin',
    component: load('Admin')
  },

  DASHBOARD: {
    path: '/admin',
    exact: true,
    component: load('users/Users')
  },

  PROFILE: {
    path: '/admin/einstellungen',
    exact: true,
    component: load('Profile')
  },

  MANAGEUSERS: {
    path: '/admin/users',
    exact: true,
    component: load('users/Users')
  },

  MANAGESESSIONS: {
    path: '/admin/sessions',
    exact: true,
    component: load('sessions/Sessions')
  },

  REPLAYSESSION: {
    path: '/admin/session/:id/play',
    exact: true,
    component: load('sessions/ReplaySession')
  },

  MANAGEVIDEOS: {
    path: '/admin/videos',
    exact: true,
    component: load('videos/Videos')
  },

  MANAGEVOUCHERS: {
    path: '/admin/vouchers',
    exact: true,
    component: load('vouchers/Vouchers')
  },

  MANAGEEXPERT_VIDEO_REQUESTS: {
    path: '/admin/expert-requests',
    exact: true,
    component: load('expertRequests/ExpertRequests')
  },

  MANAGESTATICPAGES: {
    path: '/admin/staticPages',
    exact: true,
    component: load('staticPages/Pages')
  },

  LOGOUT: {
    path: '/logout',
    exact: true,
    component: load('Logout')
  },
  LOGIN: {
    path: '/login',
    exact: true,
    component: load('Login')
  },
  NOTFOUND: {
    component: load('Sidebar')
  },

  MANAGE_FAQS: {
    path: '/admin/manage-faqs',
    exact: true,
    component: load('faq/FaqIndex'),
  },
  EDIT_FAQ: {
    path: '/admin/edit-faq/:index',
    exact: true,
    component: load('faq/EditFaq'),
  },
  ADD_FAQ: {
    path: '/admin/add-faq',
    exact: true,
    component: load('faq/AddFaq'),
  },
  MANAGE_EMAILS: {
    path: '/admin/manage-emails',
    exact: true,
    component: load('Emails/EmailsIndex'),
  },
  ADD_EMAIL: {
    path: '/admin/add-email',
    exact: true,
    component: load('Emails/AddEmail'),
  },
  EDIT_EMAIL: {
    path: '/admin/edit-email/:id',
    exact: true,
    component: load('Emails/EditEmails'),
  },
  MANAGE_SETTINGS: {
    path: '/admin/manage-settings',
    exact: true,
    component: load('common/ManageSettings'),
  },
  TRANSACTIONS: {
    path: '/admin/transactions/:transactionType?',
    exact: true,
    component: load('transactions/ManageTransactions'),
  },
  MANAGE_USERS: {
    path: '/admin/manage-user',
    exact: true,
    component: load('userRolesDetails/UserRoleDetails')
  },
  MANAGE_EXPERT_USERS: {
    path: '/admin/manage-expert-user',
    exact: true,
    component: load('userRolesDetails/ExpertUserRoleDetails')
  },
  MANAGE_ADMIN: {
    path: '/admin/manage-admin-role',
    exact: true,
    component: load('userRolesDetails/AdminRoleDetails'),
  },
  PAYOUT_REQUEST: {
    path: '/admin/payout-requests',
    exact: true,
    component: load('payout/PayoutRequest'),
  },

};