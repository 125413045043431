import axios from 'axios';
import { API_ROOT } from '../constants';
import { _dispatch } from '../utilities';
export {_dispatch};

const ROOT_URL = API_ROOT;

export function login(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/user/admin/login`
  });
}

export function fetchUsersList(params, pageNumber, limit) {
  return axios({
    method: 'GET',
    params: {...params, limit, skip: (limit * (pageNumber - 1))},
    url: `${ROOT_URL}/user/admin/list-users`
  });
}

export function fetchUserDetail(userId) {
  return axios({
    method: 'GET',
    params: {userId},
    url: `${ROOT_URL}/user/admin/list-user-by-id`
  });
}

export function toggleUserStatus(data) {
  return axios({
    method: 'PUT',
    data,
    url: `${ROOT_URL}/user/admin/activate-or-deactivate-user`
  });
}

export function addUser(data) {
  return axios({
    method: 'PUT',
    data,
    url: `${ROOT_URL}/user/admin/add-new-user`
  });
}

export function editUser(data) {
  return axios({
    method: 'PUT',
    data,
    url: `${ROOT_URL}/user/admin/update-user-profile`
  });
}


export function assignExpert(data) {
  return axios({
    method: 'POST',
    data,
    url: `${ROOT_URL}/user/admin/assign-expert-to-request`
  });
}

export function sendResetPassword(data) {
  return axios({
    method: 'POST',
    data,
    url: `${ROOT_URL}/user/customer/forgot-password`
  });
}

export function deleteUser(data) {
  return axios({
    method: 'DELETE',
    data: data,
    url: `${ROOT_URL}/user/admin/delete-user`
  });
}

export function changePassword(data) {
  return axios({
    method: 'PUT',
    data,
    url: `${ROOT_URL}/user/admin/update-user-password-token`
  });
}

export function getUserTransaction(params, pageNumber, limit, transactionsType = '') {
  return axios({
    method: 'GET',
    params: {...params, limit, skip: (limit * (pageNumber - 1))},
    url: transactionsType == 'expert-request' ? `${ROOT_URL}/user/admin/list-expert-request-transactions` : `${ROOT_URL}/user/admin/list-user-transactions`
  });
}

export function getPayoutList(params, pageNumber, limit) {
  return axios({
    method: 'GET',
    params: {...params, limit, skip: (limit * (pageNumber - 1))},
    url: `${ROOT_URL}/user/admin/payout-list`
  });
}
