import React, { Component } from 'react';

class ContentLoader extends Component {
  render() {
    return (
       <div>Loading...</div>
    );
  }
}

export default ContentLoader;
