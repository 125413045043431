import {
  _dispatch,
} from '../actions/users';


const INITIAL_STATE = {status: null};

export default function(state = INITIAL_STATE, action) {

  switch(action.type) {
    
    default:
      return state;
  }
}


